<template>
  <v-container class="customer-detail-main">
    <v-row class="tab-contant mr-0 ml-0">
      <v-col cols="12" class="">
        <div class="customer-evaluation-step text-center">
          <div class="customer-details-wrap">
            <v-container>
              <h2 class="estimate_photo_css">EVALUATION PHOTOS</h2>
              <div class="row">
                <div class="col-6">
                  <v-img
                    :src="require('../assets/003.jpg')"
                    contain
                    class="img-class"
                  >
                  </v-img>
                  <br />
                  <p class="image_text">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using
                  </p>
                </div>

                <div class="col-6">
                  <v-img
                    :src="require('../assets/003.jpg')"
                    contain
                    class="img-class"
                  >
                  </v-img>
                  <br />
                  <p class="image_text">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <v-img
                    :src="require('../assets/003.jpg')"
                    contain
                    class="img-class"
                  >
                  </v-img>
                  <br />
                  <p class="image_text">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using
                  </p>
                </div>

                <div class="col-6">
                  <v-img
                    :src="require('../assets/003.jpg')"
                    contain
                    class="img-class"
                  >
                  </v-img>
                  <br />
                  <p class="image_text">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <v-img
                    :src="require('../assets/003.jpg')"
                    contain
                    class="img-class"
                  >
                  </v-img>
                  <br />
                  <p class="image_text">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using
                  </p>
                </div>

                <div class="col-6">
                  <v-img
                    :src="require('../assets/003.jpg')"
                    contain
                    class="img-class"
                  >
                  </v-img>
                  <br />
                  <p class="image_text">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using
                  </p>
                </div>
              </div>
            </v-container>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      //date1: null,
      SportsFan: true,
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,

      dialog: false,
      dialog1: false,
      current_step: "1",
      activeBtn: 0,
    };
  },
  mounted() {
    setTimeout(function () {
      window.print();
      //window.history.go(0);
    }, 4000);
  },
};
</script>
<style type="text/css">
.header {
  display: none;
}
.text_colour {
  color: #0157a0;
}
.sub_text {
  font-weight: 500;
  font-size: 18px;
  color: #f99a35;
}
.estimate_css {
  font-weight: 500;
  float: right;
}
.sub_text_1 {
  font-size: 20px;
  font-weight: 500;
}
.text-left {
  color: white !important;
  font-weight: 500;
  font-size: 20px !important;
}
.top_bar {
  background-color: #0157a0;
}
.subtotal_class {
  font-size: 25px;
  font-weight: 600;
}

.total_css {
  text-align: right;
  font-size: 25px;
  font-weight: 600;
}

.amount_css {
  text-align: right;
  font-size: 20px;
}
.repair_text_css:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 120px;
  left: 0;
  top: 0;
  background: #055797;
}
.repair_text_css:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 250px;
  top: 120px;
  left: 0;
  z-index: 0;
  background-size: cover;
  background-image: url(../assets/trai-shape.png);
}

.header_css {
  color: white !important;
  font-weight: 500;
  font-size: 20px !important;
}
.tab-wrap-menu {
  box-shadow: none !important;
  height: auto !important;
}
.data_css {
  font-size: 20px !important;
}
.footer-text-css {
  margin-left: 0px;
  font-style: normal;
}

.footer-bottom-text {
  color: #0157a0;
}
.center_text_css {
  text-align: right;
  font-weight: 600;
  font-size: 30px;
  color: #f99a35;
}

.discount_css {
  margin-left: 0px;
  font-size: 20px;
}
.image_css {
  background-image: url("../assets/f1_l_bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 230px;
}

.letter_text {
  text-align: left;
  font-style: normal;
  line-height: 1.6;
  font-size: 20px;
}

.theme--light.v-data-table,
.theme--light.v-bottom-navigation,
tr.data_css:hover {
  background: transparent !important;
}
.total_dis {
  font-weight: 650;
  font-style: normal;
}
.total_discount_bg {
  margin-left: 0px;
  font-size: 30px;
  font-weight: 600;
  background-color: #0157a0;
  color: white;
  position: relative;
}
.repair_text_css span {
  text-align: left;
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 9;
}
.repair_text_css {
  padding: 50px 70px 190px 70px;
  position: relative;
}
.payment_term {
  margin-left: 0px;
  font-size: 23px;
  color: #0157a0;
  font-weight: 600;
}
.image_text {
  text-align: justify;
}

.estimate_photo_css {
  text-align: right !important;
  color: #0157a0;
  font-size: 47px;
}

.tab-wrap-menu button {
  margin: 0 20px !important;
}
.tab-wrap-menu button,
.tab-wrap-menu button span {
  font-size: 17px !important;
  line-height: 25px;
  font-weight: 400 !important;
  color: #989b9f !important;
  letter-spacing: 0;
  text-transform: capitalize;
}
.tab-wrap-menu button.v-btn--active,
.tab-wrap-menu button.v-btn--active span {
  color: #0157a0 !important;
}
.tab-wrap-menu button.v-btn--active:after {
  content: "" !important;
  position: absolute;
  width: 100%;
  height: 2px;
  box-shadow: none !important;
  background: #0157a0;
  bottom: -5px;
}
.tab-wrap-menu button:before {
  content: none !important;
}
.tab-wrap-menu button.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  max-width: initial !important;
  min-width: inherit !important;
}
.tab-wrap-menu button span + span {
  display: none !important;
}
.tab-wrap-menu button span:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  background: #0157a0;
  border-radius: 100%;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
}
.tab-wrap-menu button.v-btn--active span:before {
  opacity: 1;
  visibility: visible;
}
.customer-title h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}
.customer-title {
  display: flex;
  align-items: center;
}
.customer-title h6 {
  margin-left: 80px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  font-size: 19px;
  line-height: 28px;
  color: #0157a0;
  align-items: center;
}
.customer-title h6 span {
  margin-left: 19px;
}
.no-checkbox .v-input--selection-controls__input {
  display: none;
}
.phone-check .v-input--selection-controls__input {
  margin: 0 0 0 8px;
  order: 2;
}
.v-application .phone-check .primary--text,
.v-application .ac-check-box .primary--text {
  color: rgba(126, 133, 148, 0.8) !important;
}
.customer-detail-main {
  max-width: 100% !important;
  padding: 0 !important;
}
/*.v-input__control
{
  margin-top: -29px;
}*/
.calender_Css {
  margin-top: -18px;
  float: right;
  width: 45%;
  border-radius: 70px;
  background: rgba(170, 177, 185, 0.25);
}
.customer-search-wrap .col {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.customer-search-table .v-data-table {
  border-radius: 0;
  box-shadow: none !important;
}
.customer-search-table .v-data-table table {
  border: none !important;
}
.customer-search-table .v-data-table table th {
  border: none !important;
  color: #2d2d2d !important;
  font-family: Poppins;
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px !important;
  height: 50px !important;
  padding: 0px 30px !important;
}
.customer-search-table .v-data-table table td {
  border: none !important;
}
thead.v-data-table-header tr th:first-child {
  border-radius: 5px 0 0 5px;
}
thead.v-data-table-header tr th {
  background-color: #f3f3f3;
}
thead.v-data-table-header tr th:last-child {
  border-radius: 0 5px 5px 0;
}
.customer-search-table .v-data-table table tbody tr:last-child td {
  border-bottom: 1px solid #c4c4c4 !important;
}
.customer-search-table .v-data-table table td {
  padding: 0 30px !important;
  height: 50px !important;
  border-top: 1px solid #c4c4c4 !important;
  white-space: nowrap;
}
.customer-search-table .v-data-table table td:first-child {
  border-left: 1px solid #c4c4c4 !important;
  border-radius: 5px 0 0 5px;
}
.customer-search-table .v-data-table table td:last-child {
  border-right: 1px solid #c4c4c4 !important;
  border-radius: 0 5px 5px 0;
}
.customer-search-table .v-data-footer .v-data-footer__select,
.customer-search-table .v-data-footer .v-data-footer__pagination {
  display: none;
}
.customer-search-table .v-data-footer {
  margin-top: 30px;
  border: none !important;
}
.customer-search-table .v-data-footer button {
  background: #dee9f3;
}
.customer-search-table .v-data-footer button * {
  color: #0089f3 !important;
}
.customer-search-table th .v-icon:before {
  content: "\f0d8";
  font-family: FontAwesome;
  color: #000;
  font-size: 16px;
  position: relative;
  top: -5px;
  left: 10px;
}
.customer-search-table th .v-icon {
  transform: none !important;
  opacity: 1 !important;
}
.inner_div {
  padding: 10px;
}
.customer-search-table th .v-icon:after {
  content: "\f0d7";
  font-family: FontAwesome;
  opacity: 1 !important;
  background: transparent !important;
  transform: none !important;
  color: #000;
  font-style: normal;
  position: relative;
  top: 3px;
  font-size: 16px;
  left: 1px;
}
.cursor-pointer {
  cursor: pointer;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  font-size: 13px;
  padding-right: 15px !important;
  padding-left: 15px !important;
  height: 45px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding-right: 15px !important;
  padding-left: 15px !important;
}
@media only screen and (max-width: 599px) {
  .v-application .sc-title {
    margin-top: 20px !important;
  }
  .sc-title h1 {
    font-size: 22px;
  }
  .customer-search-table .v-data-footer {
    margin-top: 0;
  }
  .v-application .customer-search-wrap {
    padding-top: 0 !important;
  }
  .sc-title h1 {
    font-size: 22px;
  }
  .customer-search-table .v-data-footer {
    margin-top: 0;
  }
}
@media only screen and (max-width: 587px) {
  .customer-search-table .v-data-table table td:first-child {
    border-radius: 0;
    border-left: 0 !important;
  }
  .customer-search-table .v-data-table table td:last-child {
    border-radius: 0;
    border-right: 0 !important;
  }
  .customer-search-table .v-data-table__mobile-table-row {
    margin-bottom: 20px;
    display: block !important;
    border: 1px solid #c4c4c4;
  }
}
/*.v-btn__content
{
    color: white;
}*/

.v-icon .notranslate .material-icons .theme--light {
  display: none !important;
}

.v-application .v-btn:not(.v-btn--round).v-size--default.header-add-location {
  border-radius: 50px;
  padding: 8px 15px !important;
  height: auto !important;
  color: #ffffff !important;
}

.header-add-location {
  max-width: 140px;
}
.header-add-location a {
  border-radius: 18px !important;
  width: 11%;
  float: right !important;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0091ff;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}

.text-color-css-Insights {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  color: #7e8594 !important;
}

.v-btn {
  text-transform: uppercase !important;
}

.button_css_back_new_contact {
  float: right;
  margin-top: 2%;
  margin-right: 3%;
}
@media only screen and (max-width: 1263px) {
  .customer-details-wrap .ac-check-box ul li {
    width: 100%;
    margin: 0 !important;
  }
}
@media only screen and (max-width: 1199px) {
  .v-application .customer-detail-main .tab-wrap {
    padding-top: 20px !important;
  }
  .customer-detail-main .tab-contant > div {
    margin: 10px 0 0 0 !important;
  }
  .v-item-group.v-bottom-navigation {
    flex-wrap: wrap;
  }
  .tab-wrap-menu button.v-btn:not(.v-btn--round).v-size--default {
    margin: 9px 20px !important;
  }
}
@media only screen and (max-width: 991px) {
  .customer-details-wrap {
    margin-top: 20px;
  }
  .customer-details-wrap > div > div > div {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 599px) {
  .v-application .source-comments {
    margin-top: 0 !important;
  }
  .tab-wrap-menu button,
  .tab-wrap-menu button span {
    font-size: 14px !important;
  }
  .tab-wrap-menu button span:before {
    right: -9px;
  }
  .tab-wrap-menu button.v-btn:not(.v-btn--round).v-size--default {
    margin: 4px 10px !important;
  }
  .v-application .customer-detail-main .tab-wrap {
    padding-top: 10px !important;
  }
  .customer-detail-main .tab-contant > div {
    margin: 0 !important;
  }
  .customer-details-wrap {
    margin-top: 20px;
  }
  .customer-details-wrap > div > div > div {
    padding-top: 0;
    padding-bottom: 0;
  }
  .v-application .customer-details-wrap form > div > div {
    padding: 0 12px 0 12px !important;
  }
  .v-application .customer-details-wrap form > div > div > .row > div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .customer-title h1 {
    font-size: 22px;
    width: 100%;
  }
  .customer-title {
    flex-wrap: wrap;
  }
  .customer-title h6 {
    font-size: 16px;
    width: 100%;
    margin: 5px 0 0 0;
  }
  .customer-title img {
    max-width: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .header-add-systemAdmin {
    margin-left: 0px !important;
    margin-top: 28px !important;
  }
  .button_css_back_new_contact {
    margin-right: 10%;
  }
}

@media only screen and (max-width: 650px) and (min-width: 599px) {
  .button_css_back_new_contact {
    margin-right: 10%;
  }
}
.demo-wrap {
  display: flex;
  flex-wrap: wrap !important;
  justify-content: space-between;
}

.demo-wrap .v-input__slot .v-input--selection-controls__input {
  order: 2;
}

.demo-wrap .v-input__slot .v-input--selection-controls__input {
  margin: 0 0 0 10px;
}

.v-input__prepend-outer {
  display: none !important;
}

.customer-next-btn-custom {
  background: #0157a0;
  box-shadow: 0px 5px 10px rgba(110, 115, 243, 0.29);
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff !important;
  padding: 8px 53px;
}

.background_css .v-input__slot {
  background: rgba(170, 177, 185, 0.25);
  padding: 4px 10px;
  border-radius: 5px;
}

.background_css .v-input__slot:before {
  content: none !important;
}

.main_text_css {
  margin-left: 5%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #7e8594;
}
.background_css .v-input__slot label.v-label.v-label--active.theme--light {
  top: 15px;
}
.v-text-field {
  padding-top: 0px !important;
  margin-top: 4px !important;
}

.model-header {
  display: flex;
  align-items: center;
}
.model-select-box {
  display: flex;
  align-items: center;
  margin-left: 50px;
}
.model-select-box .v-select__slot {
  background: rgba(170, 177, 185, 0.25);
  border-radius: 5px;
}

.model-select-box .v-input {
  margin: 0 !important;
  width: 130px;
}

.model-select-box .v-input .v-input__slot {
  margin: 0;
}

.model-select-box .v-input .v-text-field__details {
  display: none !important;
}

.model-select-box .v-input .v-input__slot:before {
  content: none;
}

.model-select-box > label {
  margin-right: 10px;
}

.model-select-box .v-select__slot label.v-label.theme--light {
  left: 10px !important;
}
.model-select-box
  .v-select__slot
  label.v-label.v-label--active.theme--light.primary--text {
  transform: translateY(-24px) scale(0.75);
}

.model-select-box
  .v-select__slot
  .v-select__selection.v-select__selection--comma {
  margin-left: 10px;
}
.model-select-box .v-select__slot .v-select__selections input {
  display: none;
}
.customerclosebtn {
  background: #ff6161;
  box-shadow: 0px 5px 10px rgba(110, 115, 243, 0.29);
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff !important;
  padding: 8px 53px;
}

.customer-next-btn-custom-new-blue {
  background: #0091ff;
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-size: 15px;
  color: #ffffff !important;
  padding: 8px 6px;
}

.customer-next-btn-custom-new-dark {
  background: #0157a0;
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-size: 15px;
  color: #ffffff !important;
  padding: 8px 6px;
}
.customer-next-btn-custom-new-green {
  background: #20e0a5;
  border-radius: 50px;
  font-family: Poppins;
  font-style: normal;
  font-size: 15px;
  color: #ffffff !important;
  padding: 8px 6px;
}
.link_Css {
  color: #0157a0;
}
.text-center-css {
  margin: 0 auto;
}
.model-select-box-bids {
  display: flex;
  align-items: center;
}
.model-select-box-bids .v-select__slot {
  background: rgba(170, 177, 185, 0.25);
  border-radius: 5px;
}

.model-select-box-bids .v-input {
  margin: 0 !important;
  width: 130px;
}

.model-select-box-bids .v-input .v-input__slot {
  margin: 0;
}

.model-select-box-bids .v-input .v-text-field__details {
  display: none !important;
}

.model-select-box-bids .v-input .v-input__slot:before {
  content: none;
}

.model-select-box-bids > label {
  margin-right: 10px;
}

.model-select-box-bids .v-select__slot label.v-label.theme--light {
  left: 10px !important;
}
.model-select-box-bids
  .v-select__slot
  label.v-label.v-label--active.theme--light.primary--text {
  transform: translateY(-24px) scale(0.75);
}

.model-select-box-bids
  .v-select__slot
  .v-select__selection.v-select__selection--comma {
  margin-left: 10px;
}
.model-select-box-bids .v-select__slot .v-select__selections input {
  display: none;
}
.calender_Css .v-input__slot:before {
  border: none !important;
}

.calender_Css .v-text-field__details {
  display: none;
}

.calender_Css .v-input__slot {
  margin: 0;
}

.calender_Css .v-input {
  margin: 0 !important;
  padding: 7px 20px !important;
}
.discount_row {
  display: flex;
}

.discount_total.dt_big {
  width: 55%;
  flex: 0 0 55%;
}

.discount_total.dt_small {
  width: 15%;
  flex: 0 0 15%;
}

.discount_total h6 {
  border-bottom: 1px solid #000;
  font-size: 20px;
  font-weight: normal;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.discount_total span {
  font-size: 20px;
}
.side-image .v-image__image.v-image__image--contain {
  background-size: cover !important;
}
.side-text {
  text-align: left;
}

.side-text h2 {
  color: orange;
}

.side-text p {
  margin: 0;
  color: #fff;
}
.letter p {
  margin: 0;
}
</style>
